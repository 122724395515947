// https://johnkueh.com/articles/adding-a-use-analytics-hook
// https://github.com/react-ga/react-ga/issues/301

import { useEffect, useState } from "react";
import ReactGA from "react-ga";

export const useGoogleAnalytics = (trackingCode) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        ReactGA.initialize(trackingCode);
        setInitialized(true);
    }, [trackingCode]); // Run useEffect Only Once

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [initialized]);
};
